import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SplashScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate a delay for the splash screen
    const timeout = setTimeout(() => {
      navigate('/login'); // Navigate to the login page after the delay
    }, 2000); // Adjust the delay time as needed

    return () => clearTimeout(timeout); // Clean up the timeout on unmount
  }, [navigate]);

  return (
     <div className="splash-screen"></div>
  );
};

export default SplashScreen;

