import React, { useEffect } from 'react';
import NavBar from './NavBar';

const Header = () => {
  useEffect(() => {
    console.log('Header component rendered'); // Output a message to the console when the component is rendered
  }, []);

  return (
    <header>
      <a href="/" className="logo">
        <img src="/images/Logo_Full.png" alt="Header Logo" className="logo" />
      </a>
      <NavBar />
    </header>
  );
};

export default Header;

