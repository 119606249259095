// components/Footer.js
import React from 'react';
import { getCurrentDateTime } from '../utils/utils';

const Footer = () => {
  const dateTime = getCurrentDateTime();

  return (
    <footer>
      {/* Your footer content */}
      <p>Current Date and Time: {dateTime}</p>
    </footer>
  );
};

export default Footer;

