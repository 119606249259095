import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import LoginPage from './frontend/components/LoginPage';
import HomePage from './frontend/components/LandingPage';
import ContestForm from './frontend/components/ContestForm';
import AboutPage from './frontend/components/About';
import Success from './frontend/components/Success';
import AddCatch from './frontend/components/AddCatch';
import DefaultLayout from './frontend/components/DefaultLayout';
import SplashScreen from './frontend/components/SplashScreen';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [showSplash, setShowSplash] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate a delay for the splash screen
    const timeout = setTimeout(() => {
      setShowSplash(false);
    }, 2000); // Adjust the delay time as needed

    return () => clearTimeout(timeout); // Clean up the timeout on unmount
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
    navigate('/');
  };

  return (
    <>
      {showSplash ? (
        <SplashScreen />
      ) : (
        <DefaultLayout>
          <Routes>
            {isLoggedIn ? (
              <>
                <Route path="/" element={<HomePage />} />
                <Route path="/contestform" element={<ContestForm />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/success" element={<Success />} />
                <Route path="/addcatch" element={<AddCatch />} />
              </>
            ) : (
              <Route path="/" element={<LoginPage onLogin={handleLogin} />} />
            )}
          </Routes>
        </DefaultLayout>
      )}
    </>
  );
}

export default App;

