// utils.js

// Function to get the current date and time
export const getCurrentDateTime = () => {
  const currentDate = new Date();
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
  };
  return currentDate.toLocaleString(undefined, options);
};

// Other utility functions can be added here
export const someOtherUtilityFunction = () => {
  // ...
};

