import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginPage = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');

  const handleLogin = () => {
    setIsLoggedIn(true); // Update the login status
    // navigate('/homepage'); // Make sure this path exists in your routes
    
    // Debuggning 
    console.log('Logging in:', { username, email }); // Log the username and email
    // End debugging

    navigate('/'); // Make sure this path exists in your routes
  };

 console.log('Render - LoginPage:', { username, email }); // Log the state variables

  return (
    <div className="container">
      <h2>Login</h2>
      <div className="form-group">
        <label>Username:</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <button onClick={handleLogin} className="button">
        Login
      </button>
    </div>
  );
};

export default LoginPage;

