import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LambdaClient, InvokeCommand } from '@aws-sdk/client-lambda';

const AddCatch = () => {
  const navigate = useNavigate();
  const [dateTime, setDateTime] = useState(new Date().toISOString().slice(0, 16));
  const [species, setSpecies] = useState('');
  const [weight, setWeight] = useState('');
  const [length, setLength] = useState('');
  const [speciesData, setSpeciesData] = useState([]);

  useEffect(() => {
    const fetchSpeciesData = async () => {
      try {
        // Create an instance of the Lambda client
        const lambdaClient = new LambdaClient({ region: 'eu-north-1' });

        // Create the InvokeCommand to call your Lambda function
        const command = new InvokeCommand({
          FunctionName: 'GetSpecies',
          Payload: JSON.stringify({}), // Optional payload
        });

        // Invoke the Lambda function
        const response = await lambdaClient.send(command);

        // Extract the species data from the response
        const data = JSON.parse(response.Payload);

        setSpeciesData(data);
      } catch (error) {
        console.error('Error fetching species data:', error);
      }
    };

    fetchSpeciesData();
  }, []);

  const handleDateTimeChange = (event) => {
    setDateTime(event.target.value);
  };

  const handleSpeciesChange = (event) => {
    setSpecies(event.target.value);
  };

  const handleWeightChange = (event) => {
    setWeight(event.target.value);
  };

  const handleLengthChange = (event) => {
    setLength(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Save the catch data
    // Implement the logic to save the catch using your preferred method (API call, database update, etc.)
    // ...
    // After saving, navigate back to the home page or another relevant page
    navigate('/');
  };

  return (
    <div>
      <h2>Add Catch</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Date/Time:</label>
          <input type="datetime-local" value={dateTime} onChange={handleDateTimeChange} />
        </div>
        <div>
          <label>Species:</label>
          <div>
            {speciesData.map((species) => (
              <button
                key={species.id}
                type="button"
                onClick={handleSpeciesChange}
                value={species.name}
              >
                <img src={`/images/species/${species.image}`} alt={species.name} />
              </button>
            ))}
          </div>
        </div>
        <div>
          <label>Weight (kg):</label>
          <input type="number" step="0.01" value={weight} onChange={handleWeightChange} />
        </div>
        <div>
          <label>Length (cm):</label>
          <input type="number" step="0.1" value={length} onChange={handleLengthChange} />
        </div>
        <div>
          <label>Photo:</label>
          {/* Implement the logic to capture and save the photo */}
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default AddCatch;

