import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { formatDate } from '../utils/dateUtils';
import { validateContest, validatePlayers } from '../utils/validation';

const ContestForm = () => {
  const navigate = useNavigate();
  const [contestName, setContestName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [numPlayers, setNumPlayers] = useState(1);
  const [players, setPlayers] = useState([]);
  const [location, setLocation] = useState('');
  const [step, setStep] = useState(1);

  const handleNextStep = () => {
    if (step === 1) {
      const isValid = validateContest(contestName, startDate, endDate);
      if (isValid) {
        setStep(step + 1);
      }
    } else if (step === 2) {
      const isValid = validatePlayers(players);
      if (isValid) {
        setStep(step + 1);
      }
    } else if (step === 3) {
      setStep(step + 1);
    } else if (step === 4) {
      setStep(step + 1);
    } else if (step === 5) {
      setStep(step + 1);
    } else if (step === 6) {
      setStep(step + 1);
    } else if (step === 7) {
      // Check if it's the last step
      // If it's the last step, you can perform any final validations or save the form data
      // For now, we'll navigate to the success page
      handleSave();
    }
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handleSave = () => {
    // Save contest data and navigate to success page
    navigate('/success');
  };

  const renderStepOne = () => (
    <div>
      <h2>Step 1: Create New Contest</h2>
      <label>
        Contest Name:
        <input
          type="text"
          value={contestName}
          onChange={(e) => setContestName(e.target.value)}
        />
      </label>
      <button disabled={!contestName} onClick={handleNextStep} className="button-group">
        Next
      </button>
    </div>
  );

  const renderStepTwo = () => (
    <div>
      <h2>Step 2: Select Start Date</h2>
      <label>
        Start Date:
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
      </label>
      <button disabled={!startDate} onClick={handleNextStep} className="button-group">
        Next
      </button>
      <button onClick={handlePrevStep} className="button-group">
        Back
      </button>
    </div>
  );

  const renderStepThree = () => (
    <div>
      <h2>Step 3: Select End Date</h2>
      <label>
        End Date:
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </label>
      <button disabled={!endDate} onClick={handleNextStep} className="button-group">
        Next
      </button>
      <button onClick={handlePrevStep} className="button-group">
        Back
      </button>
    </div>
  );

  const renderStepFour = () => (
    <div>
      <h2>Step 4: Select Number of Players</h2>
      <label>
        Number of Players:
        <input
          type="number"
          min={1}
          max={4}
          value={numPlayers}
          onChange={(e) => setNumPlayers(Number(e.target.value))}
        />
      </label>
      <button onClick={handleNextStep} className="button-group">
        Next
      </button>
      <button onClick={handlePrevStep} className="button-group">
        Back
      </button>
    </div>
  );

  const renderStepFive = () => (
    <div>
      <h2>Step 5: Input Player Names</h2>
      {Array.from(Array(numPlayers), (player, index) => (
        <div key={index}>
          <label>
            Player {index + 1} Name:
            <input
              type="text"
              value={players[index] || ''}
              onChange={(e) => handlePlayerNameChange(e.target.value, index)}
            />
          </label>
        </div>
      ))}
      <button onClick={handleNextStep} className="button-group">
        Next
      </button>
      <button onClick={handlePrevStep} className="button-group">
        Back
      </button>
    </div>
  );

  const renderStepSix = () => (
    <div>
      <h2>Step 6: Select Location</h2>
      <label>
        Location:
        <input
          type="text"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />
      </label>
      <button disabled={!location} onClick={handleNextStep} className="button-group">
        Next
      </button>
      <button onClick={handlePrevStep} className="button-group">
        Back
      </button>
    </div>
  );

  const renderSummary = () => (
    <div>
      <h2>Summary</h2>
      <p>Contest Name: {contestName}</p>
      <p>Start Date: {formatDate(startDate)}</p>
      <p>End Date: {formatDate(endDate)}</p>
      <p>Number of Players: {numPlayers}</p>
      <p>Players:</p>
      <ul>
        {players.map((player, index) => (
          <li key={index}>{player}</li>
        ))}
      </ul>
      <p>Location: {location}</p>
      <button onClick={handlePrevStep} className="button-group">
        Back
      </button>
      <button onClick={handleSave} className="button-group">
        Save
      </button>
    </div>
  );

  const handlePlayerNameChange = (name, index) => {
    const updatedPlayers = [...players];
    updatedPlayers[index] = name;
    setPlayers(updatedPlayers);
  };

  let stepComponent;

  switch (step) {
    case 1:
      stepComponent = renderStepOne();
      break;
    case 2:
      stepComponent = renderStepTwo();
      break;
    case 3:
      stepComponent = renderStepThree();
      break;
    case 4:
      stepComponent = renderStepFour();
      break;
    case 5:
      stepComponent = renderStepFive();
      break;
    case 6:
      stepComponent = renderStepSix();
      break;
    case 7:
      stepComponent = renderSummary();
      break;
    default:
      stepComponent = renderStepOne();
  }

  return (
    <div>
      {stepComponent}
    </div>
  );
};

export default ContestForm;

