// DefaultLayout.js

import React from 'react';
import Header from './Header';
import Footer from './Footer';

const DefaultLayout = ({ children }) => {
  return (
    <div className="main">
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default DefaultLayout;

