import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className="navbar">
      <Link to="/" className="navbar-brand">Logo</Link>
      <ul className="nav">
        <li className="nav-item">
          <Link to="/" className="nav-link">Home</Link>
        </li>
        <li className="nav-item">
          <Link to="/about" className="nav-link">About</Link>
        </li>
        <li className="nav-item">
          <Link to="/contestform" className="nav-link">Create Contest</Link>
        </li>
         <li className="nav-item">
          <Link to="/addcatch" className="addcatch">Add Catch </Link>
        </li>
         <li className="nav-item">
          <Link to="/loginpage" className="loginpage">Login Page</Link>
        </li>

        {/* Add more nav items as needed */}
      </ul>
    </nav>
  );
};

export default Navbar;

