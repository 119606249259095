import React from 'react';

const LandingPage = () => {

  return (
    <div className="main">
	  TEST
    </div>
  );
};

export default LandingPage;

