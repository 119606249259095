import React from 'react';

const AboutPage = () => {
  return (
    <div>
      <h2>About Us</h2>
      <p>This is the about page content.</p>
    </div>
  );
};

export default AboutPage;

